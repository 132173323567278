body {
    background-color: #FFFFFF;
}

.bg-image {
    margin-top: -50px;
    margin-bottom: -50px;
    padding-top: 60px;
    padding-bottom: 300px;
    position: relative;
    background: #013B82;
    overflow: hidden;
}

.bg-image .container {
    position: relative;
    z-index: 2;
}

.bg-image:before {
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.9;
    background-image: url("/storage/icons/bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
}

.zoom {
    transition: transform ease-in-out 0.5s;
}

.zoom:hover {
    transform: scale(1.3);
}

.navbar {
    z-index: 3;
}

.border-login {
    border: 2px solid #FFFFFF;
    border-radius: 2px;
}

@media (max-width: 575.98px) {
    .img-logo {
        width: 200px;
    }

    .img-logo-sm {
        width: 280px;
    }

    iframe {
        width: 252px;
        height: 142px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .img-logo {
        width: 240px;
    }

    .img-logo-sm {
        width: 280px;
    }

    iframe {
        width: 336px;
        height: 189px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .img-logo {
        width: 280px;
    }

    .img-logo-sm {
        width: 280px;
    }

    iframe {
        width: 336px;
        height: 189px;
    }

}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .img-logo {
        width: 320px;
    }

    .img-logo-sm {
        width: 280px;
    }

    iframe {
        width: 476px;
        height: 268px;
    }
}

@media (min-width: 1200px) {
    .img-logo {
        width: 340px;
    }

    .img-logo-sm {
        width: 280px;
    }

    iframe {
        width: 560px;
        height: 315px;
    }
}

/* backgrounds */
.bg-primary-color {
    background-color: #013B82;
}

.bg-secondary-color {
    background-color: #01548F;
}

.bg-third-color {
    background-color: #022e64;
}

.bg-highlight-color {
    background-color: #DB312E;
}

/* text colors */
.text-primary-color {
    color: #013B82;
}

.text-secondary-color {
    color: #01548F;
}

.text-third-color {
    color: #022e64;
}

.text-highlight-color {
    color: #DB312E;
}

.text-white {
    color: #FFFFFF;
}

/* Modal */
.btn-close-modal {
    opacity: 1;
}

.btn-close-modal:hover {
    opacity: 0.8;
}


/* Botões */

.btn-cancel {
    background-color: #DB312E;
    color: #FFFFFF;
    font-weight: bold;
}

.btn-cancel:hover {
    opacity: 0.8;
    color: #FFFFFF;
}

.btn-cancel:focus {
    box-shadow: none;
}

.btn-confirm {
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
}

.btn-confirm:hover {
    opacity: 0.8;
    color: #FFFFFF;
}

.btn-confirm:focus {
    box-shadow: none;
}

/* navbar */
.nav-border-bottom {
    border-bottom: 4px solid #FFFFFF;
}

.nav-border-right {
    border-right: 2px solid #FFFFFF;
}

@media (max-width: 991.98px) {
    .nav-border-right {
        border-right: 0px;
    }
}

.nav-link {
    font-weight: bold;
}

.nav-item {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 18px;
}

.icon-toggle {
    border: 1px solid #FFFFFF !important;
}

.img-perfil {
    background-color: #FFFFFF !important;
    padding: 2px;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

/* cards */
.title-card {
    font-size: 22px;
}

.text-card {
    font-size: 18px;
    margin-bottom: 3px;
}

.shadow-card {
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.7);
}

.card-border-bottom {
    border-bottom: 3px solid #FFFFFF;
}

.border-radius-card-header {
    border-radius: 20px 0 0 0 !important;
}

.border-radius-card-body {
    border-radius: 0 0 20px 0 !important;
}

.card-position-icon {
    margin-top: -30px;
    margin-left: -10px;
}

@media (max-width: 575.98px) {
    .card-position-icon {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .card-position-icon {
        margin-top: -30px;
        margin-left: 50px;
    }
}

.border-radius-card-header-right {
    border-radius: 0 20px 0 0 !important;
}

.border-radius-card-body-right {
    border-radius: 0 0 0 20px !important;
}

.card-position-icon-right {
    margin-top: -30px;
    margin-left: -40px;
}

@media (max-width: 575.98px) {
    .card-position-icon-right {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .card-position-icon-right {
        margin-top: -30px;
        margin-left: -40px;
    }
}

/* filtro de categorias */
.category-filter {
    border-radius: 50px;
    border: 2px solid #DB312E;
    color: #FFFFFF;
}

.category-filter:hover {
    border-radius: 50px;
    border: 2px solid #DB312E;
    background-color: #FFFFFF;
    color: #DB312E !important;
}

.category-link {
    text-decoration: none;
}

.category-link:hover {
    text-decoration: none;
}

.category-number {
    font-size: 15px;
    margin-bottom: 0px;
}

.category-text {
    font-size: 10px;
    margin-bottom: 2px;
}

/* ------------- */

.text-category {
    font-size: 15px;
}

.btn-category {
    border: none;
    padding: 0px 0px 12px 10px;
    box-shadow: none;
    font-size: 16px;
}

.btn-category:focus {
    box-shadow: none;
}

.btn.btn-category:hover {
    color: #013B82;
}

.select-category {
    color: #013B82;
    border: none !important;
    box-shadow: none;
}

.select-category:focus {
    color: #013B82;
    border: none !important;
    box-shadow: none;
}

.option-category {
    color: #FFFFFF;
    font-weight: bold;
    background-color: #013B82;
    font-size: 13px;
    line-height: 15px;
    margin-top: 10px;
}

/* barra de pesquisa */
.input-search-post {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    font-size: 15px;
}

.input-search-post:focus {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.input-search-post:focus-visible {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.form-control.input-search-post {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.input-search-post::-webkit-input-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.input-search-post:-moz-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.input-search-post::-moz-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

.input-search-post:-ms-input-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #FFFFFF !important;
    box-shadow: 0 0 0px 1000px #013B82 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.btn-search {
    border-radius: 20px;
    border: 2px solid #013B82;
    font-size: 15px;
    color: #013B82;
    font-weight: bold;
}

.btn-search:hover {
    color: #FFFFFF;
    background-color: #013B82;
}

.btn-search:focus {
    box-shadow: none;
}

/* cards de publicações */
.card-publication {
    border: none;
    border: 3px solid #013B82;
    border-radius: 50px 0 50px 0;
    -webkit-box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 3px 3px 0px rgba(0, 0, 0, 0.5);
}

.title-card-publication {
    font-size: 20px;
}

.description-card-publication {
    font-size: 15px;
    line-height: 18px;
}

.date-card-publication {
    font-size: 12px;
}

.card-publication-position-icon {
    margin-left: 60px;
    margin-top: -50px;
}

@media (max-width: 575.98px) {
    .card-publication-position-icon {
        display: none;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .card-publication-position-icon {
        margin-left: 60px;
        margin-top: -50px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .card-publication-position-icon {
        margin-left: 30px;
        margin-top: -50px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .card-publication-position-icon {
        margin-left: 85px;
        margin-top: -50px;
    }
}

@media (min-width: 1200px) {
    .card-publication-position-icon {
        margin-left: 60px;
        margin-top: -50px;
    }
}

/* modal */
.border-modal-post {
    border: 5px solid #013B82;
    border-radius: 70px 0 70px 0;
}

.title-modal-post {
    font-size: 30px;
}

.subtitle-modal-post {
    font-size: 22px;
    line-height: 22px;
}

.text-modal-post {
    font-size: 18px;
    line-height: 18px;
}

.info-modal-post {
    font-size: 13px;
    line-height: 16px;
    color: #7e7e7e;
}

.btn-close-modal {
    border-radius: 50%;
    background-color: #FFFFFF;
    color: #DB312E;
    font-weight: bold;
    padding: 1px;
}

.btn-close-modal:hover {
    opacity: 0.8;
    color: #DB312E;
    box-shadow: none;
}

.icon-btn-close-modal {
    font-size: 30px;
}

/* rodape */
.text-footer {
    font-size: 16px;
}



/* PAGINA DE WIKIS */
.border-filter-right {
    border-right: 3px solid #013B82;
}

@media (max-width: 991.98px) {
    .border-filter-right {
        border-right: none;
    }
}

.btn-new-wiki {
    background-color: #DB312E;
    color: #FFFFFF;
    border: 2px solid #DB312E;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
}

.btn-new-wiki:hover {
    background-color: #FFFFFF;
    color: #DB312E;
    border: 2px solid #DB312E;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
}

.btn-new-wiki:focus {
    box-shadow: none;
}

.card-filter-wiki {
    border: none;
    background-color: #FFFFFF;
}

.card-filter-wiki-header {
    border: none;
    background-color: #FFFFFF;
    padding: 0px;
}

.title-wikis {
    font-size: 50px;
}

.btn-accordion-filter {
    color: #013B82 !important;
    font-weight: bold !important;
    font-size: 18px;
}

.btn-accordion-filter:focus {
    box-shadow: none;
}

.link-accordion-filter {
    text-decoration: none;
    color: #000000;
    font-size: 15px;
}

.link-accordion-filter:hover {
    text-decoration: none;
    color: #000000;
}

.title-wiki {
    font-size: 18px;
}

.text-wiki {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 8px;
}

.date-wiki {
    font-size: 12px;
    margin-bottom: 10px;
}

.btn-view-more {
    font-weight: bold;
}

.btn-view-more:hover {
    color: #013B82;
}

.btn-view-more:focus {
    box-shadow: none;
    font-weight: bold;
}

.border-publication-bottom {
    border-bottom: 2px solid #c1c1c1;
}

/* PAGINA DE PERFIL */
.divisor-perfil {
    background-color: #013B82;
    height: 2px;
}

.input-perfil {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    font-size: 13px;
}

.input-perfil:focus {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.input-perfil:focus-visible {
    border: none;
    border-radius: 20px;
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.form-control.input-perfil {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.input-perfil::-webkit-input-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.input-perfil:-moz-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.input-perfil::-moz-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

.input-perfil:-ms-input-placeholder {
    color: #FFFFFF;
    font-weight: bold;
    font-size: 13px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    -webkit-text-fill-color: #FFFFFF !important;
    box-shadow: 0 0 0px 1000px #013B82 inset;
    transition: background-color 5000s ease-in-out 0s;
}

.label-input-perfil {
    font-size: 15px;
    padding-left: 15px;
    margin-bottom: 0px;
}

.form-row.form-group {
    margin-bottom: 8px;
}

.btn-update-perfil {
    border-radius: 20px;
    border: 2px solid #013B82;
    font-size: 15px;
    color: #013B82;
    font-weight: bold;
}

.btn-update-perfil:hover {
    color: #FFFFFF;
    background-color: #013B82;
}

.btn-update-perfil:focus {
    box-shadow: none;
}

.btn-update-password {
    border-radius: 20px;
    border: 2px solid #DB312E;
    font-size: 15px;
    color: #DB312E;
    font-weight: bold;
}

.btn-update-password:hover {
    color: #FFFFFF;
    background-color: #DB312E;
}

.btn-update-password:focus {
    box-shadow: none;
}

.btn-update-image {
    border-radius: 20px;
    border: 2px solid #000000;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
}

.btn-update-image:hover {
    color: #FFFFFF;
    background-color: #000000;
}

.btn-update-image:focus {
    box-shadow: none;
}

.title-card-team {
    font-size: 18px;
    margin-bottom: 0px;
}

.text-card-team {
    font-size: 14px;
    margin-bottom: 0px;
}

.card-header-team {
    border: none;
    border-bottom: 2px solid #FFFFFF;
    padding: 8px;
    border-radius: 20px 0 0 0 !important;
}

.card-body-team {
    padding: 10px;
    border-radius: 0 0 20px 0 !important;
}

.btn-modal-feedback {
    padding: 0px;
}

.btn-modal-feedback:focus {
    box-shadow: none;
}

/* PAGINA DE AGENDA DE CONTATOS */
.title-contact-book {
    font-size: 28px;
}

.btn-filter-contact-book {
    padding: 0px;
    padding-bottom: 10px;
    font-size: 24px;
}

.btn-filter-contact-book:focus {
    box-shadow: none;
}

.btn-filter {
    background-color: #000000;
    font-size: 12px;
    color: #ffdf00;
    font-weight: bold;
    border-radius: 20px;
}

.btn-filter:hover {
    background-color: #ffdf00;
    font-size: 12px;
    color: #000000;
    font-weight: bold;
    border-radius: 20px;
}

.btn-filter:focus {
    box-shadow: none;
}

.icon-filter {
    font-size: 18px;
}

.table td,
.table th {
    padding: 2px 4px 3px 8px;
}

.table-hover tbody tr:hover {
    background-color: rgba(51, 91, 139, 0.1);
}

.table-bordered td,
.table-bordered th {
    border: 1px solid #013B82 !important;
    font-size: 13px;
    color: #000000;
}

.table-bordered thead td,
.table-bordered thead th {
    border: 1px solid #013B82 !important;
    font-size: 16px;
    color: #FFFFFF;
    background-color: #013B82;
}

.pagination {
    margin-bottom: 0px;
}

.page-link {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: #FFFFFF !important;
    color: #013B82 !important;
    text-decoration: none !important;
    border: 1px solid #013B82;
}

.page-link:hover {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    background-color: #013B82 !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    border: 1px solid #013B82;
}

.page-item.active .page-link {
    background-color: #013B82 !important;
    color: #FFFFFF !important;
    text-decoration: none !important;
    border: 1px solid #013B82;
}

/* AGENDA */
.vc-container {
    border: none !important;
    border-radius: 10px;
}

.vc-pane {
    background-color: #013B82 !important;
    border-radius: 5px;
    -webkit-box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.7);
    box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.7);
}

.vc-weekday {
    color: #ffdf00 !important;

}

.vc-header {
    background-color: #022e64;
    border: none;
    border-radius: 5px 5px 0 0;
    border-bottom: 3px solid #FFFFFF;
    margin-bottom: 10px !important;
    padding-bottom: 10px !important;
}

.icon-agenda {
    color: #ffdf00 !important;
    font-size: 14px;
    padding-left: 2px;
    padding-right: 2px;
}

/* PAGINA DE UTILITÁRIOS */
.title-utilities {
    font-size: 32px;
}

/* PAGINA DE ATIVIDADES */
.title-activities {
    font-size: 24px;
}

.btn-collapse-activity {
    padding: 0px;
}

.btn-collapse-activity:focus {
    box-shadow: none;
}

.icon-collapse-title {
    font-size: 20px;
}

.card-activities-body {
    padding: 8px 12px 8px 12px;
    border-radius: 20px 0 !important;
}

.text-activities {
    font-size: 15px;
    margin-bottom: 6px;
}

.date-activities {
    font-size: 13px;
    margin-bottom: 4px;
}

.img-activities {
    width: 24px;
    height: 24px;
}

.description-activity {
    font-size: 20px;
    line-height: 22px;
}

.text-activity {
    font-size: 16px;
    line-height: 18px;
}

.alternative-activity {
    font-size: 15px;
    line-height: 17px;
}

/* PAGINA DE ARQUIVOS */
.title-files {
    font-size: 24px;
}

.border-file-bottom {
    border-bottom: 2px solid #4e4e4e;
}

.title-file {
    font-size: 20px;
}

.text-file {
    font-size: 18px;
}

.link-file {
    text-decoration: none;
    color: #000000;
}

.link-file:hover {
    text-decoration: none;
    color: #013B82;
}

@media (max-width: 575.98px) {
    .title-file {
        font-size: 16px;
    }

    .text-file {
        font-size: 14px;
    }
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .title-file {
        font-size: 16px;
    }

    .text-file {
        font-size: 14px;
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .title-file {
        font-size: 18px;
    }

    .text-file {
        font-size: 16px;
    }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
    .title-file {
        font-size: 18px;
    }

    .text-file {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {
    .title-file {
        font-size: 20px;
    }

    .text-file {
        font-size: 18px;
    }
}

.btn-new-file {
    background-color: #DB312E;
    color: #FFFFFF;
    border: 2px solid #DB312E;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
}

.btn-new-file:hover {
    background-color: #FFFFFF;
    color: #DB312E;
    border: 2px solid #DB312E;
    border-radius: 50px;
    font-weight: bold;
    font-size: 12px;
}

.btn-new-file:focus {
    box-shadow: none;
}

/* CARD E COMPONENTES GENERICOS */
.card-generic {
    border: 2px solid #013B82;
}

.card-header-generic {
    border-radius: 0px !important;
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.modal-generic {
    border: 2px solid #013B82;
    border-radius: 7px;
}

.modal-header-generic {
    background-color: #013B82;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 18px;
}

.breadcrumb-item a {
    text-decoration: none;
    color: #000000;
}

.dropdown-item {
    color: #013B82;
    text-decoration: none;
    font-weight: bold;
}

.dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #013B82;
}

.dropdown-item:focus {
    color: #fff;
    text-decoration: none;
    background-color: #013B82;
}

.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #013B82;
}

.btn-close-modal-generic {
    border-radius: 50%;
    background-color: #000000;
    color: #FFFFFF;
    font-weight: bold;
    padding: 1px;
}

.btn-close-modal-generic:hover {
    opacity: 1;
    color: #FFFFFF;
    box-shadow: none;
}

.btn-primary-generic {
    border: 2px solid #013B82;
    font-size: 15px;
    color: #013B82;
    font-weight: bold;
}

.btn-primary-generic:hover {
    color: #FFFFFF;
    background-color: #013B82;
}

.btn-primary-generic:focus {
    box-shadow: none;
}

.btn-danger-generic {
    border: 2px solid #DB312E;
    font-size: 15px;
    color: #DB312E;
    font-weight: bold;
}

.btn-danger-generic:hover {
    color: #FFFFFF;
    background-color: #DB312E;
}

.btn-danger-generic:focus {
    box-shadow: none;
}

.btn-black-generic {
    border: 2px solid #000000;
    font-size: 15px;
    color: #000000;
    font-weight: bold;
}

.btn-black-generic:hover {
    color: #FFFFFF;
    background-color: #000000;
}

.btn-black-generic:focus {
    box-shadow: none;
}

.border-primary-circle {
    border-radius: 100px;
    border: 2px solid #013B82;
}

.alert-success {
    color: #1d643b;
    background-color: #1d643b05;
    border: 2px solid #1d643b;
}

.alert-danger {
    color: #DB312E;
    background-color: #db312e05;
    border: 2px solid #DB312E;
}

/* FIM CARD E COMPONENTES GENERICOS */

/* CROPPER */
.input-cropper[type="file"] {
    display: none;
}

.cropper-area {
    padding-left: 20px;
    padding-right: 10px;
    width: 100% !important;
    height: 200px !important;
}

.img-cropper {
    width: 100% !important;
    height: 200px !important;
}

.img-cropper div {
    max-width: 100% !important;
    max-height: 200px !important;
}

.preview-area {
    padding-left: 10px;
    padding-right: 20px;
    width: 100% !important;
    height: 200px !important;
}

.preview {
    width: 100%;
    height: 200px;
    height: calc(372px * (1/1));
    overflow: hidden;
}

.crop-placeholder {
    width: 100%;
    height: 200px;
    background: #ccc;
}

.cropped-image img {
    max-width: 100%;
    height: 200px;
}

.btn-cropper-primary {
    padding: 2px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 2px solid #013B82;
    font-size: 17px;
    line-height: 33px;
    text-align: center;
    color: #013B82;
    font-weight: bold;
}

.btn-cropper-primary:hover {
    color: #FFFFFF;
    background-color: #013B82;
}

.btn-cropper-primary:focus {
    box-shadow: none;
}

.btn-cropper-secondary {
    padding: 2px;
    height: 40px;
    width: 40px;
    border-radius: 20px;
    border: 2px solid #DB312E;
    font-size: 17px;
    line-height: 33px;
    text-align: center;
    color: #DB312E;
    font-weight: bold;
}

.btn-cropper-secondary:hover {
    color: #FFFFFF;
    background-color: #DB312E;
}

.btn-cropper-secondary:focus {
    box-shadow: none;
}

/* FIM CROPPER */

/* PRE-CARREGAMENTO DE DOM*/
#loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #013B82;
    /* cor do background que vai ocupar o body */
    z-index: 999;
    /* z-index para jogar para frente e sobrepor tudo */
}

#loader .inner {
    position: absolute;
    top: 50%;
    /* centralizar a parte interna do preload (onde fica a animação)*/
    left: 50%;
    transform: translate(-50%, -50%);
}

.bolas>div {
    display: inline-block;
    background-color: #ffdf00;
    width: 35px;
    height: 35px;
    border-radius: 100%;
    margin: 5px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: animarBola;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

.bolas>div:nth-child(1) {
    animation-duration: 1.75s;
    animation-delay: 0;
}

.bolas>div:nth-child(2) {
    animation-duration: 1.75s;
    animation-delay: 0.12s;
}

.bolas>div:nth-child(3) {
    animation-duration: 1.75s;
    animation-delay: 0.24s;
}

@keyframes animarBola {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }

    16% {
        -webkit-transform: scale(0.1);
        transform: scale(0.1);
        opacity: 0.7;
    }

    33% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
}

/* FIM PRE-CARREGAMENTO DE DOM*/